import React from 'react'

const WrapperContext = React.createContext(null)

export function WrapperBase(props) {
  const { className, children } = props
  const [title, setTitle] = React.useState()
  const [pageFeatures, setPageFeatures] = React.useState({})
  React.useEffect(() => {
    window.document.title = title ? title : ''
    const body = window.document.body
    const newClassNames = Object.entries(pageFeatures).filter(entry => entry[1]).map(entry => entry[0])
    newClassNames.forEach(newClassName => {
      body.classList.add(newClassName)
    })
    return () => {
      window.document.title = ''
      newClassNames.forEach(newClassName => {
        body.classList.remove(newClassName)
      })
    }
  }, [pageFeatures, title])
  return (
    <WrapperContext.Provider value={{
      setTitle,
      title,
      setPageFeatures(features) {
        setPageFeatures(currentFeatures => {
          //if (!features.length) return currentFeatures
          //currentFeatures = {...currentFeatures}
          features.forEach(feature => {
            currentFeatures[feature] = (currentFeatures[feature] || 0) + 1
          })
          return currentFeatures
        })
        return () => {
          setPageFeatures(currentFeatures => {
            //if (!features.length) return currentFeatures
            //currentFeatures = {...currentFeatures}
            features.forEach(feature => {
              currentFeatures[feature]--
              if (currentFeatures[feature] === 0) {
                delete currentFeatures[feature]
              }
            })
            return currentFeatures
          })
        }
      },
    }}>
      {children}
    </WrapperContext.Provider>
  )
}

export function useWrapperContext() {
  const wrapperContext = React.useContext(WrapperContext)
  return {...wrapperContext}
}

