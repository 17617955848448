import React from 'react'
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { blue, red } from '@material-ui/core/colors'
import SettingsIcon from '@material-ui/icons/Settings'
import Typography from '@material-ui/core/Typography'

const ProjectPlayerStartSettingsIcon = (props) => {
  return (
    <Typography style={{_marginLeft: -13, _marginRight: -13}}>start</Typography>
  )
}

const SIZING = {
  headerHeight: 64,
  mobileNavHeight: 56,
  tabBarHeight: 48,
}

const alyvrPallette = {
  light: {
    darkBackground: '#2a3d5b',
    primary: {
      initial: '#00afec',
      inverse: '#00afec',
      text: '#ffffff',
    },
    secondary: {
      initial: '#00afec',
      inverse: '#1f2e44',
      text: '#ffffff',
    },
    text: {
      primary: '#2a3d5b',
      secondary: '#b4b4b5',
    },
    error: '#b44',
  },
  dark: {
  },
}

export const alyvrThemeProps = {
  ProjectPlayer: {
    SettingsIcon: ProjectPlayerStartSettingsIcon,
  },
}

const lightTheme = createMuiTheme({
  AlyvrProps: alyvrThemeProps,
  overrides: {
    MuiButton: {
      root: {
	borderRadius: '0px',
      },
      contained: {
	borderRadius: '0px',
	boxShadow: '0px'
      }
    },
    MuiDivider: {
      root: {
        '.MuiList-root &': {
          margin: '.5em',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: alyvrPallette.light.primary.initial,
      },
      colorPrimary: {
        color: alyvrPallette.light.text.primary,
      },
      colorSecondary: {
        color: alyvrPallette.light.text.secondary,
      },
    },
    MuiFab: {
      root: {
        '&.ProjectPlayerSettingsFab': {
          borderRadius: 0,
          minHeight: 'auto',
          height: 'auto',
          width: 'auto',
          lineHeight: '1rem',
          '& .MuiTypography-body1': {
            padding: 10,
            lineHeight: '1rem',
          },
        },
      },
      primary: {
        '&.ProjectPlayerSettingsFab': {
          backgroundColor: 'rgb(14, 116, 65)',
          '&:hover': {
            backgroundColor: 'rgb(7, 58, 33)',
          },
        },
      },
    },
    MuiSvgIcon: {
      colorAction: {
        color: 'gold',
      },
    },
  },
  typography: {
    fontFamily: `Mulish, sans-serif`,
  },
  sizing: SIZING,
  palette: {
    text: {
      primary: alyvrPallette.light.text.primary,
      secondary: alyvrPallette.light.text.secondary,
    },
    primary: {
      main: alyvrPallette.light.primary.initial,
      dark: alyvrPallette.light.primary.inverse,
      contrastText: alyvrPallette.light.primary.text,
      background: lighten(alyvrPallette.light.primary.inverse, 0.9),

      //light: `#677eaa`,
      veryLightPink: `#e5e5e5`,
      pageBackground: `#f5f5f5`,
      copyLight: `#828282`,
      accentCopy: `#000000`,
      borders: `#e5e5e5`,
    },
    secondary: {
      main: alyvrPallette.light.secondary.initial,
      dark: alyvrPallette.light.secondary.inverse,
      contrastText: alyvrPallette.light.secondary.text,
      background: lighten(alyvrPallette.light.secondary.inverse, 0.9),
    },
    error: {
      main: alyvrPallette.light.error,
      background: lighten(alyvrPallette.light.error, 0.9),
    },
    ownerStatus: {
      main: 'gold',
    },
    content: {
      background: `#FFFFFF`,
      hoverBackground: `#f3f7fc`,
      emptyState: `#F6F7F9`
    },
    menu: {
      text: `rgba(0, 0, 0, 0.87)`,
      hover: '#efefef',
    },
    button: {
      main: `#00afec`,
      mainHover: `#545e1e`,
      tab: `rgba(114, 127, 40, 0.15)`,
      tabHover: `rgba(114, 127, 40, 0.5)`
    },
    navigation: {
      header: '#081933',
      sidebar: `#FFF`
    },
    type: 'light',
  },
})

const darkTheme = createMuiTheme({
  AlyvrProps: alyvrThemeProps,
  typography: {
    fontFamily: `Mulish, sans-serif`,
  },
  sizing: SIZING,
  palette: {
    primary: {
      light: `#677eaa`,
      main: '#2a3d5b',
      veryLightPink: `#e5e5e5`,
      contrastText: `#FFFFFF`,
      pageBackground: `#252628`,
      accentCopy: `#000000`,
      borders: `#e5e5e5`
    },
    content: {
      background: red,/*`#FFFFFF`,*/
      hoverBackground: `#f3f7fc`
    },
    menu: {
      text: `rgba(242, 242, 242, 0.4)`,
      hover: '#2a3d5b',
    },
    button: {
      main: `#727f28`,
      mainHover: `#545e1e`,
    },
    navigation: {
      header: `#000000`,
      sidebar: `#060606`
    },
    type: 'dark',
  },
})

export function ThemeWrapper(Component) {
  return function ThemeComponent(props) {
    return (
      <MuiThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    )
  }
}
