import React from 'react'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  '@media (min-aspect-ratio: 1/1) and (max-height: 640px)': {
    toolbar: {
      'body.project-viewer &': {
        height: 0,
        minHeight: 0,
        paddingLeft:0,
        paddingRight:0,
        '& > *': {
          top: 32,
          position: 'relative',
        },
      },
    },
    appBar: {
      'body.project-viewer &': {
        //display: 'none',
        height:0,
      },
    },
  },

  toolbar: {
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.navigation.header,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.navigation.header,
    },
  },
}))

export default function AppBarSimple(props) {
  const { children, className } = props
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AppBar
      position="sticky"
      className={classNames(classes.appBar, className)}
      elevation={isMobile ? 0 : 1}
    >
      <Toolbar className={classes.toolbar}>{children}</Toolbar>
    </AppBar>
  )
}
