import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DvrAppBarSimple from './appbar-simple'
import { WrapperBase, useWrapperContext } from './wrapper-base'

const useStyles = makeStyles((theme) => ({
  root: {
    display: `flex`,
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '100vh',
    '& > *': {
      flex: 0,
      flexBasis: 'auto',
    }
  },
  appBar: {
    paddingLeft: 42,
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: 0,
  },
  link: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function NoTabsWrapper({children}) {
  const classes = useStyles()
  const { title } = useWrapperContext()

  return (
    <div className={classes.root}>
      <DvrAppBarSimple title={title} className={classes.appBar}>
        <a href="/app" className={classes.link}>
          <span className="logo" />
        </a>
      </DvrAppBarSimple>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}

export default function NoTabsWrapperWrapped(props) {
  return <WrapperBase><NoTabsWrapper {...props}/></WrapperBase>
}
